import { LineChart } from "@tremor/react";
import { useQuery } from "react-query";
import AuthService from "../../misc/AuthService";
import api from "../../misc/api";

export function CoinChart() {
  const fetchData = async () => {
    try {
      const response = await api.get(
        `/admin/orders/chart?year=2024`,
        AuthService.getAxiosAdminHeaders(),
      );
      const formattedData = response.data.map((item) => ({
        date: item.date,
        activeUsers: item.activeUsers,
        totalUsers: item.totalUsers,
        buyOrders: item.buyOrders,
        sellOrders: item.sellOrders,
        totalBuyOrders: item.totalBuyOrders,
        totalSellOrders: item.totalSellOrders,
      }));
      return formattedData;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw error;
    }
  };

  const { data } = useQuery("chartData", fetchData);

  // if (isLoading) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error fetching data</div>;
  // }

  return (
    <LineChart
      className="h-80"
      data={data}
      index="date"
      categories={[
        "activeUsers",
        "totalUsers",
        "buyOrders",
        "sellOrders",
        "totalBuyOrders",
        "totalSellOrders",
      ]}
      colors={["indigo", "rose", "green", "red", "yellow", "purple"]}
      yAxisWidth={40}
      showAnimation={true}
      onValueChange={(v) => console.log(v)}
    />
  );
}
