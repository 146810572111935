import React, { Fragment } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import OrderInfo from "./include/OrderInfo";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import "./styles/dashboard.css";
import SideNavMobile from "./include/sideNavMobile";
import ltc from "../../iamges/ltc.png";
import eth from "../../iamges/eth.png";
import { CoinChart } from "./CoinChart";
import OrderStats from "./OrderStats";
import Threshhold from "./Threshhold";
import api from "../../misc/api";

class AdminDashboard extends React.Component {
  constructor(props) {
    AuthService.checkAdminProtected();
    super(props);
    this.state = {
      sidebar: false,
      adminInfo: AuthService.getAdminAuth().admin,
      isLoading: false,
      isRequesting: false,
      orderID: "",
      showOrderInfo: false,
      dashboardStats: {
        pending_orders: {
          pending_buy_orders: 0,
          pending_sell_orders: 0,
        },
        buy_orders_stats: {
          total_amount: 2000,
          total_orders: "196",
          cryptocurrency: {
            bitcoin: {
              amount: "1,300",
              percentage: "70",
            },
            ltc: {
              amount: "300",
              percentage: "30",
            },
            eth: {
              amount: "200",
              percentage: "20",
            },
            usdt: {
              amount: "700",
              percentage: "30",
            },
          },
        },
        sell_orders_stats: {
          total_amount: "1,000",
          total_orders: "56",
          cryptocurrency: {
            bitcoin: {
              amount: "600",
              percentage: "60",
            },
            ltc: {
              amount: "300",
              percentage: "30",
            },
            eth: {
              amount: "200",
              percentage: "20",
            },
            usdt: {
              amount: "400",
              percentage: "40",
            },
          },
        },
        rates_info: {
          bitcoin_buy_rate: "0.00",
          bitcoin_sell_rate: "0.00",
          usdt_buy_rate: "0.00",
          usdt_sell_rate: "0.00",
          ltc_buy_rate: "0.00",
          ltc_sell_rate: "0.00",
          eth_buy_rate: "0.00",
          eth_sell_rate: "0.00",
          buy_orders_normal_fee: "0.00",
          buy_orders_priority_fee: "0.00",
        },
        payouts: {
          total_payouts: "9,680",
          total_bank_payouts: "3,245",
          total_momo_payouts: "6,435",
        },
        users_stats: {
          total_users: "234",
          total_active_users: "121",
        },
        buy_sell_chart: [
          {
            labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
            data: [
              {
                name: "Buy Orders",
                data: [30, 40, 35, 50, 49, 60, 70],
              },
              {
                name: "Sell Orders",
                data: [130, 420, 335, 540, 459, 660, 770],
              },
            ],
            total_buy: "2200.00",
            total_sell: "1100.00",
            period: "week",
          },
          {
            labels: [
              "Mar 1st",
              "Mar 6th",
              "Mar 12th",
              "Mar 18th",
              "Mar 24th",
              "Mar 30th",
            ],
            data: [
              {
                name: "Buy Orders",
                data: [30, 40, 35, 50, 49, 60],
              },
              {
                name: "Sell Orders",
                data: [130, 420, 335, 540, 459, 660],
              },
            ],
            total_buy: "1110",
            total_sell: "2200",
            period: "month",
          },
          {
            labels: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sept",
              "Oct",
              "Nov",
              "Dec",
            ],
            data: [
              {
                name: "Buy Orders",
                data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 60, 70, 91],
              },
              {
                name: "Sell Orders",
                data: [
                  130, 420, 335, 540, 459, 660, 770, 891, 125, 459, 660, 770,
                ],
              },
            ],
            total_buy: "3330",
            total_sell: "4440",
            period: "year",
          },
        ],
        order_list: [],
      },
      selectedChartPeriod: {
        total_buy: "200.00",
        total_sell: "100.00",
        options: {
          chart: {
            id: "buy_sell_chart",
          },
          xaxis: {
            categories: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
          },
          dataLabels: {
            enabled: false,
          },
          plotOptions: {
            bar: {
              horizontal: !1,
              columnWidth: ["40%"],
              borderRadius: 4,
            },
          },
          colors: ["#04c8c8", "#E4E6EF"],
          grid: {
            borderColor: "#EFF2F5",
            strokeDashArray: 4,
            yaxis: {
              lines: {
                show: !0,
              },
            },
          },
          states: {
            normal: {
              filter: {
                type: "none",
                value: 0,
              },
            },
            hover: {
              filter: {
                type: "none",
                value: 0,
              },
            },
            active: {
              allowMultipleDataPointsSelection: false,
              filter: {
                type: "none",
                value: 0,
              },
            },
          },
        },
        series: [
          {
            name: "Buy Orders",
            data: [30, 40, 35, 50, 49, 60, 70],
          },
          {
            name: "Sell Orders",
            data: [130, 420, 335, 540, 459, 660, 770],
          },
        ],
      },
      buySellPeriod: "week",
      waitList: {
        users: [],
        total: 0,
      },
    };
  }

  getDashboardStats() {
    this.setState({
      ...this.state,
      isLoading: true,
      isRequesting: true,
    });

    // Getting all assets ...
    api
      .get(
        `/assets`,
        AuthService.getAxiosAdminHeaders()
      )
      .then((res) => {
        const data = res.data;

        // Update the state with asset rates information directly
        this.setState({
          ...this.state,
          isRequesting: false,
          dashboardStats: {
            ...this.state.dashboardStats,
            rates_info: {
              bitcoin_buy_rate: data[3]?.data?.buyRate,
              bitcoin_sell_rate: data[3]?.data?.sellRate,
              ltc_buy_rate: data[2]?.data?.buyRate,
              ltc_sell_rate: data[2]?.data?.sellRate,
              eth_buy_rate: data[1]?.data?.buyRate,
              eth_sell_rate: data[1]?.data?.sellRate,
              usdt_buy_rate: data[0]?.data?.buyRate,
              usdt_sell_rate: data[0]?.data?.sellRate,

              buy_orders_normal_fee: "0.00",
              buy_orders_priority_fee: "0.00",
            },
          },
          isLoading: false, // Set loading to false when data is successfully fetched
        });
      })
      .catch((error) => {
        try {
          let errorResponse = error.response.data;

        

          let errorMessage = "Error: Could not connect to server";
          if (errorResponse.hasOwnProperty("message")) {
            errorMessage = errorResponse.message;
          }

          this.setState({
            ...this.state,
            isLoading: false,
            isRequesting: false,
            errorMessage: errorMessage,
          });
        } catch (e) {
          window.location = "/server-offline";
        }
      });

    // Getting all users ...
    api
      .get(
        `/admin/orders/stats`,
        AuthService.getAxiosAdminHeaders()
      )
      .then((res) => {
        const data = res.data;

        this.setState({
          ...this.state,
          isLoading: false,
          dashboardStats: {
            ...this.state.dashboardStats,
            buy_orders_stats: {
              ...this.state.dashboardStats.buy_orders_stats,
              total_amount:
                data[3]?.buyOrdersAmoun +
                data[2]?.buyOrdersAmoun +
                data[1]?.buyOrdersAmoun +
                data[0]?.buyOrdersAmoun,
              total_orders:
                data[3]?.buyOrdersCount +
                data[2]?.buyOrdersCount +
                data[1]?.buyOrdersCount +
                data[0]?.buyOrdersCount,
              cryptocurrency: {
                ...this.state.dashboardStats.buy_orders_stats.cryptocurrency,
                bitcoin: {
                  amount: data[3]?.buyOrdersAmoun,
                  percentage:
                    parseFloat(
                      data[3]?.buyOrdersAmoun /
                        (data[0]?.buyOrdersAmoun +
                          data[1]?.buyOrdersAmoun +
                          data[2]?.buyOrdersAmoun +
                          data[3]?.buyOrdersAmoun)
                    ) * 100,
                },
                ltc: {
                  amount: data[2]?.buyOrdersAmoun,
                  percentage:
                    parseFloat(
                      data[2]?.buyOrdersAmoun /
                        (data[0]?.buyOrdersAmoun +
                          data[1]?.buyOrdersAmoun +
                          data[2]?.buyOrdersAmoun +
                          data[3]?.buyOrdersAmoun)
                    ) * 100,
                },
                eth: {
                  amount: data[1]?.buyOrdersAmoun,
                  percentage:
                    parseFloat(
                      data[1]?.buyOrdersAmoun /
                        (data[0]?.buyOrdersAmoun +
                          data[1]?.buyOrdersAmoun +
                          data[2]?.buyOrdersAmoun +
                          data[3]?.buyOrdersAmoun)
                    ) * 100,
                },
                usdt: {
                  amount: data[0]?.buyOrdersAmoun,
                  percentage:
                    parseFloat(
                      data[0]?.buyOrdersAmoun /
                        (data[0]?.buyOrdersAmoun +
                          data[1]?.buyOrdersAmoun +
                          data[2]?.buyOrdersAmoun +
                          data[3]?.buyOrdersAmoun)
                    ) * 100,
                },
              },
            },
            sell_orders_stats: {
              ...this.state.dashboardStats.sell_orders_stats.cryptocurrency,
              total_amount:
                data[0]?.sellOrdersAmount +
                data[1]?.sellOrdersAmount +
                data[2]?.sellOrdersAmount +
                data[3]?.sellOrdersAmount,
              total_orders:
                data[0]?.sellOrdersCount +
                data[1]?.sellOrdersCount +
                data[2]?.sellOrdersCount +
                data[3]?.sellOrdersCount,
              cryptocurrency: {
                ...this.state.dashboardStats.sell_orders_stats.cryptocurrency,
                bitcoin: {
                  amount: data[3]?.sellOrdersAmount,
                  percentage:
                    parseFloat(
                      data[3]?.sellOrdersAmount /
                        (data[0]?.sellOrdersAmount +
                          data[1]?.sellOrdersAmount +
                          data[2]?.sellOrdersAmount +
                          data[3]?.sellOrdersAmount)
                    ) * 100,
                },
                ltc: {
                  amount: data[2]?.sellOrdersAmount,
                  percentage:
                    parseFloat(
                      data[2]?.sellOrdersAmount /
                        (data[0]?.sellOrdersAmount +
                          data[1]?.sellOrdersAmount +
                          data[2]?.sellOrdersAmount +
                          data[3]?.sellOrdersAmount)
                    ) * 100,
                },
                eth: {
                  amount: data[1]?.sellOrdersAmount,
                  percentage:
                    parseFloat(
                      data[1]?.sellOrdersAmount /
                        (data[0]?.sellOrdersAmount +
                          data[1]?.sellOrdersAmount +
                          data[2]?.sellOrdersAmount +
                          data[3]?.sellOrdersAmount)
                    ) * 100,
                },
                usdt: {
                  amount: data[0]?.sellOrdersAmount,
                  percentage:
                    parseFloat(
                      data[0]?.sellOrdersAmount /
                        (data[0]?.sellOrdersAmount +
                          data[1]?.sellOrdersAmount +
                          data[2]?.sellOrdersAmount +
                          data[3]?.sellOrdersAmount)
                    ) * 100,
                },
              },
            },
          },
        });
      })
      .catch((error) => {
        try {
          let errorResponse = error.response.data;

    

          let errorMessage = "Error: Could not connect to server";
          if (errorResponse.hasOwnProperty("message")) {
            errorMessage = errorResponse.message;
          }

          this.setState({
            ...this.state,
            isLoading: false,
            isRequesting: false,
            errorMessage: errorMessage,
          });
        } catch (e) {
          console.log("error", e);
          window.location = "/server-offline";
        }
      });
  }

  updateOrders = (orderAction) => {
    if (orderAction) {
      this.getDashboardStats();
    }
    this.forceUpdate();
    this.setState({
      ...this.state,
      showOrderInfo: false,
    });
    this.getDashboardStats();
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      isLoading: true,
    });

    setTimeout(() => {
      this.getDashboardStats();
    }, 500);
  }

  render() {
    return (
      <Fragment>
        <MetaTags>
          <title>Admin Dashboard - HodlPay</title>
        </MetaTags>
        <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
          <div className="d-flex flex-column flex-root">
            <div className="page d-flex flex-row flex-column-fluid">
              {this.state.sidebar && (
                <SideNavMobile
                  close={() => this.setState({ ...this.state, sidebar: false })}
                />
              )}
              <SideNav className={"bigScreen"} active="dashboard" />

              <div
                style={{ minHeight: "100vh", overflow: "auto" }}
                className="p-0 wrapper d-flex flex-column flex-row-fluid"
                id="kt_wrapper"
              >
                <div className="header-mobile py-3">
                  <div className="container d-flex flex-stack">
                    <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                      <a href="/admin/dashboard">
                        <img
                          alt="Logo"
                          src="/assets/media/logos/hodlpay-black.svg"
                          className="h-35px"
                        />
                      </a>
                    </div>

                    <button
                      className="btn btn-icon btn-active-color-primary"
                      onClick={() =>
                        this.setState({
                          ...this.state,
                          sidebar: !this.state.sidebar,
                        })
                      }
                    >
                      <span className="svg-icon svg-icon-2x me-n1">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                            fill="black"
                          />
                          <path
                            opacity="0.3"
                            d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                            fill="black"
                          />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
                <Header title={`Dashboard`} />

                <div
                  className={`adminDashboard content container d-flex flex-column flex-column-fluid bg-blue-900 `}
                  id="kt_content"
                >
                  <div className="" id="kt_content_container">
                    <div
                      className="adminDash-sec1 flex a-i-s w-full mb-5 "
                      style={{ height: "fit-content" }}
                    >
                      <div
                        className="ordersStats flex j-c-b w-full"
                        style={{ height: "350px" }}
                      >
                        <div
                          className={`buyOrderStats ${
                            this.state.isLoading && "loadingSection"
                          } card card-flush w-full h-100 `}
                        >
                          <div className={`card-header pt-5`}>
                            <div className="card-title d-flex flex-column">
                              <span className="text-primary pt-1 fw-bold fs-6">
                                <b>
                                  {
                                    this.state.dashboardStats.buy_orders_stats
                                      .total_orders
                                  }
                                </b>{" "}
                                Buy Orders Stats
                              </span>
                              <div className="d-flex align-items-center">
                                <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">
                                  $
                                </span>
                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                                  {this.state.dashboardStats.buy_orders_stats.total_amount.toFixed(
                                    2
                                  )}
                                </span>
                                <span className="svg-icon svg-icon-primary svg-icon-3x ms-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <circle
                                        fill="#000000"
                                        opacity="0.3"
                                        cx="20.5"
                                        cy="12.5"
                                        r="1.5"
                                      />
                                      <rect
                                        fill="#000000"
                                        opacity="0.3"
                                        transform="translate(12.000000, 6.500000) rotate(-15.000000) translate(-12.000000, -6.500000) "
                                        x="3"
                                        y="3"
                                        width="18"
                                        height="7"
                                        rx="1"
                                      />
                                      <path
                                        d="M22,9.33681558 C21.5453723,9.12084552 21.0367986,9 20.5,9 C18.5670034,9 17,10.5670034 17,12.5 C17,14.4329966 18.5670034,16 20.5,16 C21.0367986,16 21.5453723,15.8791545 22,15.6631844 L22,18 C22,19.1045695 21.1045695,20 20,20 L4,20 C2.8954305,20 2,19.1045695 2,18 L2,6 C2,4.8954305 2.8954305,4 4,4 L20,4 C21.1045695,4 22,4.8954305 22,6 L22,9.33681558 Z"
                                        fill="#000000"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="card-body pt-2 pb-4 align-items-center w-full">
                            <div className="d-flex align-items-center py-3 mt-3">
                              <div className="symbol symbol-30px me-2">
                                <span className="symbol-label bg-light-warning">
                                  <img
                                    src="/assets/media/svg/brand-logos/btc.svg"
                                    className="h-50 align-self-center"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column w-full">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-boldest fs-6 text-dark">
                                    Bitcoin: $
                                    {
                                      this.state.dashboardStats.buy_orders_stats
                                        .cryptocurrency.bitcoin.amount
                                    }
                                  </span>
                                  <span className="fw-bolder fs-6 text-gray-400">
                                    {parseFloat(
                                      this.state.dashboardStats.buy_orders_stats
                                        .cryptocurrency.bitcoin.percentage
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </div>
                                <div className="h-4px mx-3 w-100 bg-light-warning rounded">
                                  <div
                                    className="bg-warning rounded h-4px"
                                    role="progressbar"
                                    style={{
                                      width: `${this.state.dashboardStats.buy_orders_stats.cryptocurrency.bitcoin.percentage}%`,
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center py-3 mt-3">
                              <div className="symbol symbol-30px me-2">
                                <span className="symbol-label bg-sky-100">
                                  <img
                                    src={ltc}
                                    className="h-50 align-self-center"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-boldest fs-6 text-dark">
                                    LTC: $
                                    {
                                      this.state.dashboardStats.buy_orders_stats
                                        .cryptocurrency.ltc.amount
                                    }
                                  </span>
                                  <span className="fw-bolder fs-6 text-gray-400">
                                    {parseFloat(
                                      this.state.dashboardStats.buy_orders_stats
                                        .cryptocurrency.ltc.percentage
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </div>
                                <div className="h-4px mx-3 w-100 bg-sky-100 rounded">
                                  <div
                                    className="bg-sky-400 rounded h-4px"
                                    role="progressbar"
                                    style={{
                                      width: `${this.state.dashboardStats.buy_orders_stats.cryptocurrency.ltc.percentage}%`,
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center py-3 mt-3">
                              <div className="symbol symbol-30px me-2">
                                <span className="symbol-label bg-gray-300">
                                  <img
                                    src={eth}
                                    className="h-50 align-self-center"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-boldest fs-6 text-dark">
                                    ETH: $
                                    {
                                      this.state.dashboardStats.buy_orders_stats
                                        .cryptocurrency.eth.amount
                                    }
                                  </span>
                                  <span className="fw-bolder fs-6 text-gray-400">
                                    {parseFloat(
                                      this.state.dashboardStats.buy_orders_stats
                                        .cryptocurrency.eth.percentage
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </div>
                                <div className="h-4px mx-3 w-100 bg-gray-100 rounded">
                                  <div
                                    className="bg-gray-400 rounded h-4px"
                                    role="progressbar"
                                    style={{
                                      width: `${this.state.dashboardStats.buy_orders_stats.cryptocurrency.eth.percentage}%`,
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center py-3 mt-3">
                              <div className="symbol symbol-30px me-2">
                                <span className="symbol-label bg-light-success">
                                  <img
                                    src="/assets/media/svg/brand-logos/usdt.svg"
                                    className="h-50 align-self-center"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-boldest fs-6 text-dark">
                                    USDT: $
                                    {
                                      this.state.dashboardStats.buy_orders_stats
                                        .cryptocurrency.usdt.amount
                                    }
                                  </span>
                                  <span className="fw-bolder fs-6 text-gray-400">
                                    {parseFloat(
                                      this.state.dashboardStats.buy_orders_stats
                                        .cryptocurrency.usdt.percentage
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </div>
                                <div className="h-4px mx-3 w-100 bg-light-success rounded">
                                  <div
                                    className="bg-success rounded h-4px"
                                    role="progressbar"
                                    style={{
                                      width: `${this.state.dashboardStats.buy_orders_stats.cryptocurrency.usdt.percentage}%`,
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-4"></div>
                            {/* <div className="d-flex flex-stack">
                              <div className="d-flex align-items-center me-5">
                                <div className="symbol symbol-30px me-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-3 svg-icon-gray-600">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                        <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                                        <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="black" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                                <div className="me-5">
                                  <small className="text-gray-800 fw-bolder text-hover-primary fs-6">Pending Buy Orders</small>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="text-gray-800 fw-bolder fs-6 me-3">{this.state.dashboardStats.pending_orders.pending_buy_orders}</span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        {/* <OrderStats/> */}
                        <div
                          className={`sellOrderStats ${
                            this.state.isLoading && "loadingSection"
                          } card card-flush w-full mr-1 ml-1 h-100`}
                        >
                          <div className="card-header pt-5">
                            <div className="card-title d-flex flex-column">
                              <span className="text-dark pt-1 fw-bold fs-6">
                                <b>
                                  {
                                    this.state.dashboardStats.sell_orders_stats
                                      .total_orders
                                  }
                                </b>{" "}
                                Sell Orders Stats
                              </span>
                              <div className="d-flex align-items-center">
                                <span className="fs-4 fw-bold text-gray-400 me-1 align-self-start">
                                  $
                                </span>
                                <span className="fs-2hx fw-bolder text-dark me-2 lh-1">
                                  {Number(
                                    this.state.dashboardStats.sell_orders_stats
                                      .total_amount
                                  ).toFixed(2)}
                                </span>
                                <span className="svg-icon svg-icon-dark svg-icon-3x ms-3">
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                  >
                                    <g
                                      stroke="none"
                                      stroke-width="1"
                                      fill="none"
                                      fill-rule="evenodd"
                                    >
                                      <rect
                                        x="0"
                                        y="0"
                                        width="24"
                                        height="24"
                                      />
                                      <path
                                        d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z"
                                        fill="#000000"
                                        opacity="0.3"
                                        transform="translate(11.500000, 12.000000) rotate(-345.000000) translate(-11.500000, -12.000000) "
                                      />
                                      <path
                                        d="M2,6 L21,6 C21.5522847,6 22,6.44771525 22,7 L22,17 C22,17.5522847 21.5522847,18 21,18 L2,18 C1.44771525,18 1,17.5522847 1,17 L1,7 C1,6.44771525 1.44771525,6 2,6 Z M11.5,16 C13.709139,16 15.5,14.209139 15.5,12 C15.5,9.790861 13.709139,8 11.5,8 C9.290861,8 7.5,9.790861 7.5,12 C7.5,14.209139 9.290861,16 11.5,16 Z M11.5,14 C12.6045695,14 13.5,13.1045695 13.5,12 C13.5,10.8954305 12.6045695,10 11.5,10 C10.3954305,10 9.5,10.8954305 9.5,12 C9.5,13.1045695 10.3954305,14 11.5,14 Z"
                                        fill="#000000"
                                      />
                                    </g>
                                  </svg>
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="card-body pt-2 pb-4 align-items-center">
                            <div className="d-flex align-items-center py-3 mt-3">
                              <div className="symbol symbol-30px me-2">
                                <span className="symbol-label bg-light-warning">
                                  <img
                                    src="/assets/media/svg/brand-logos/btc.svg"
                                    className="h-50 align-self-center"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-boldest fs-6 text-dark">
                                    Bitcoin: $
                                    {
                                      this.state.dashboardStats
                                        .sell_orders_stats.cryptocurrency
                                        .bitcoin.amount
                                    }
                                  </span>
                                  <span className="fw-bolder fs-6 text-gray-400">
                                    {parseFloat(
                                      this.state.dashboardStats
                                        .sell_orders_stats.cryptocurrency
                                        .bitcoin.percentage
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </div>
                                <div className="h-4px mx-3 w-100 bg-light-warning rounded">
                                  <div
                                    className="bg-warning rounded h-4px"
                                    role="progressbar"
                                    style={{
                                      width: `${this.state.dashboardStats.sell_orders_stats.cryptocurrency.bitcoin.percentage}%`,
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center py-3 mt-3">
                              <div className="symbol symbol-30px me-2">
                                <span className="symbol-label bg-sky-100">
                                  <img
                                    src={ltc}
                                    className="h-50 align-self-center"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-boldest fs-6 text-dark">
                                    LTC: $
                                    {
                                      this.state.dashboardStats
                                        .sell_orders_stats.cryptocurrency.ltc
                                        .amount
                                    }
                                  </span>
                                  <span className="fw-bolder fs-6 text-gray-400">
                                    {parseFloat(
                                      this.state.dashboardStats
                                        .sell_orders_stats.cryptocurrency.ltc
                                        .percentage
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </div>
                                <div className="h-4px mx-3 w-100 bg-sky-100 rounded">
                                  <div
                                    className="bg-sky-400 rounded h-4px"
                                    role="progressbar"
                                    style={{
                                      width: `${this.state.dashboardStats.sell_orders_stats.cryptocurrency.ltc.percentage}%`,
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center py-3 mt-3">
                              <div className="symbol symbol-30px me-2">
                                <span className="symbol-label bg-gray-300">
                                  <img
                                    src={eth}
                                    className="h-50 align-self-center"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-boldest fs-6 text-dark">
                                    ETH: $
                                    {
                                      this.state.dashboardStats
                                        .sell_orders_stats.cryptocurrency.eth
                                        .amount
                                    }
                                  </span>
                                  <span className="fw-bolder fs-6 text-gray-400">
                                    {parseFloat(
                                      this.state.dashboardStats
                                        .sell_orders_stats.cryptocurrency.eth
                                        .percentage
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </div>
                                <div className="h-4px mx-3 w-100 bg-gray-100 rounded">
                                  <div
                                    className="bg-gray-300 rounded h-4px"
                                    role="progressbar"
                                    style={{
                                      width: `${this.state.dashboardStats.sell_orders_stats.cryptocurrency.eth.percentage}%`,
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="d-flex align-items-center py-3 mt-3">
                              <div className="symbol symbol-30px me-2">
                                <span className="symbol-label bg-light-success">
                                  <img
                                    src="/assets/media/svg/brand-logos/usdt.svg"
                                    className="h-50 align-self-center"
                                    alt=""
                                  />
                                </span>
                              </div>
                              <div className="d-flex align-items-center flex-column w-100">
                                <div className="d-flex justify-content-between w-100 mt-auto mb-2">
                                  <span className="fw-boldest fs-6 text-dark">
                                    USDT: $
                                    {
                                      this.state.dashboardStats
                                        .sell_orders_stats.cryptocurrency.usdt
                                        .amount
                                    }
                                  </span>
                                  <span className="fw-bolder fs-6 text-gray-400">
                                    {parseFloat(
                                      this.state.dashboardStats
                                        .sell_orders_stats.cryptocurrency.usdt
                                        .percentage
                                    ).toFixed(4)}
                                    %
                                  </span>
                                </div>
                                <div className="h-4px mx-3 w-100 bg-light-success rounded">
                                  <div
                                    className="bg-success rounded h-4px"
                                    role="progressbar"
                                    style={{
                                      width: `${this.state.dashboardStats.sell_orders_stats.cryptocurrency.usdt.percentage}%`,
                                    }}
                                    aria-valuenow="50"
                                    aria-valuemin="0"
                                    aria-valuemax="100"
                                  ></div>
                                </div>
                              </div>
                            </div>
                            <div className="separator separator-dashed my-4"></div>
                            {/* <div className="d-flex flex-stack">
                              <div className="d-flex align-items-center me-5">
                                <div className="symbol symbol-30px me-5">
                                  <span className="symbol-label">
                                    <span className="svg-icon svg-icon-3 svg-icon-gray-600">
                                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="black" />
                                        <rect x="11" y="17" width="7" height="2" rx="1" transform="rotate(-90 11 17)" fill="black" />
                                        <rect x="11" y="9" width="2" height="2" rx="1" transform="rotate(-90 11 9)" fill="black" />
                                      </svg>
                                    </span>
                                  </span>
                                </div>
                                <div className="me-5">
                                  <small className="text-gray-800 fw-bolder text-hover-primary fs-6">Pending Sell Orders</small>
                                </div>
                              </div>
                              <div className="d-flex align-items-center">
                                <span className="text-gray-800 fw-bolder fs-6 me-3">{this.state.dashboardStats.pending_orders.pending_sell_orders}</span>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:flex-row  w-full ">
                        <div
                          className={`buyOrderStats ${
                            this.state.isLoading && "loadingSection"
                          } card card-flush w-full `}
                          style={{ height: "350px" }}
                        >
                          <OrderStats />
                        </div>

                        <div
                          className={`buyOrderStats ${
                            this.state.isLoading && "loadingSection"
                          } card card-flush w-full mx-2 `}
                          style={{ height: "350px" }}
                        >
                         <Threshhold/>
                        </div>
                      </div>
                    </div>

                    <div
                      className="sec2-waitlist flex a-i-s w-100 border mb-5"
                      style={{ height: "fit-content" }}
                    >
                      <div
                        className="adminDash-sec2 flex a-i-s w-100"
                        style={{ height: "350px" }}
                      >
                        <div
                          className={`currencyRates ${
                            this.state.isLoading && "loadingSection"
                          } card card-flush w-100 mr-1`}
                        >
                          <div className="card-header pt-5">
                            <div className="card-title d-flex flex-column">
                              <span className="text-gray-400 pt-1 fw-bold fs-6">
                                Current Rates
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col lg:flex-row">
                            <div className="card-body align-items-center pt-2">
                              <div className="d-flex flex-stack mt-5">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label bg-light-warning">
                                      <img
                                        src="/assets/media/svg/brand-logos/btc.svg"
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href={`/admin/settings`}
                                      className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                    >
                                      Bitcoin Buy Rate
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bolder fs-6 me-3">
                                    <small className="text-muted">GHS </small>{" "}
                                    {
                                      this.state.dashboardStats.rates_info
                                        .bitcoin_buy_rate
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="separator separator-dashed my-4"></div>
                              <div className="d-flex flex-stack">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label bg-light-warning">
                                      <img
                                        src="/assets/media/svg/brand-logos/btc.svg"
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href={`/admin/settings`}
                                      className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                    >
                                      Bitcoin Sell Rate
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bolder fs-6 me-3">
                                    <small className="text-muted">GHS </small>{" "}
                                    {
                                      this.state.dashboardStats.rates_info
                                        .bitcoin_sell_rate
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="separator separator-dashed my-4"></div>
                              <div className="d-flex flex-stack">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label bg-light-success">
                                      <img
                                        src="/assets/media/svg/brand-logos/usdt.svg"
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href={`/admin/settings`}
                                      className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                    >
                                      USDT Buy Rate
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bolder fs-6 me-3">
                                    <small className="text-muted">GHS </small>{" "}
                                    {
                                      this.state.dashboardStats.rates_info
                                        .usdt_buy_rate
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="separator separator-dashed my-4"></div>
                              <div className="d-flex flex-stack mb-5">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label bg-light-success">
                                      <img
                                        src="/assets/media/svg/brand-logos/usdt.svg"
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href={`/admin/settings`}
                                      className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                    >
                                      USDT Sell Rate
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bolder fs-6 me-3">
                                    <small className="text-muted">GHS </small>{" "}
                                    {
                                      this.state.dashboardStats.rates_info
                                        .usdt_sell_rate
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="card-body align-items-center pb-4 ">
                              <div className="d-flex flex-stack ">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label bg-sky-400">
                                      <img
                                        src={ltc}
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href={`/admin/settings`}
                                      className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                    >
                                      Litecoin Buy Rate
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bolder fs-6 me-3">
                                    <small className="text-muted">GHS </small>{" "}
                                    {
                                      this.state.dashboardStats.rates_info
                                        .ltc_buy_rate
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="separator separator-dashed my-4"></div>
                              <div className="d-flex flex-stack">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label bg-sky-400">
                                      <img
                                        src={ltc}
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href={`/admin/settings`}
                                      className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                    >
                                      Litecoin Sell Rate
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bolder fs-6 me-3">
                                    <small className="text-muted">GHS </small>{" "}
                                    {
                                      this.state.dashboardStats.rates_info
                                        .ltc_sell_rate
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="separator separator-dashed my-4"></div>
                              <div className="d-flex flex-stack">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label bg-gray-300">
                                      <img
                                        src={eth}
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href={`/admin/settings`}
                                      className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                    >
                                      Ethereum Buy Rate
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bolder fs-6 me-3">
                                    <small className="text-muted">GHS </small>{" "}
                                    {
                                      this.state.dashboardStats.rates_info
                                        .eth_buy_rate
                                    }
                                  </span>
                                </div>
                              </div>
                              <div className="separator separator-dashed my-4"></div>
                              <div className="d-flex flex-stack mb-5">
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-30px me-3">
                                    <span className="symbol-label bg-gray-300">
                                      <img
                                        src={eth}
                                        className="h-50 align-self-center"
                                        alt=""
                                      />
                                    </span>
                                  </div>
                                  <div className="me-5">
                                    <a
                                      href={`/admin/settings`}
                                      className="text-gray-800 fw-bolder text-hover-primary fs-6"
                                    >
                                      Ethereum Sell Rate
                                    </a>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center">
                                  <span className="text-gray-800 fw-bolder fs-6 me-3">
                                    <small className="text-muted">GHS </small>{" "}
                                    {
                                      this.state.dashboardStats.rates_info
                                        .eth_sell_rate
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* Waiting List users .... */}
                      <div
                        className="waitingList flex a-i-s w-100"
                        style={{ height: "350px" }}
                      >
                        <div
                          className={`waitList-card ${
                            this.state.isLoading && "loadingSection"
                          } card card-flush w-100 mr-1`}
                        >
                          <div className="card-header pt-5 w-100">
                            <div className="card-title d-flex flex j-c-b w-100"></div>
                          </div>
                          <div className="card-body align-items-center pb-2 pt-2">
                            {/* chart */}
                            <CoinChart />
                          </div>
                        </div>
                      </div>
                    </div>

                    <Footer />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.state.showOrderInfo && (
          <OrderInfo
            orderID={this.state.orderID}
            callback={true}
            orderUpdate={this.updateOrders}
          />
        )}
      </Fragment>
    );
  }
}

export default AdminDashboard;
