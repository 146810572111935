import React, { Fragment, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import { TableLoader } from "./include/Loaders";
import Footer from "./include/Footer";
import AuthService from "../../misc/AuthService";
import format from "../../misc/formatData";
import Paginate from "../client/include/Paginate";
import SideNavMobile from "./include/sideNavMobile";
import { useHistory } from "react-router-dom";
import api from "../../misc/api";

function WaitList() {
  AuthService.checkAdminProtected();
  const history = useHistory();
  const [state, setState] = useState({
    sidebar: false,
    isLoading: false,
    isRequesting: false,
    users: [],
    total: 0,
    search: "",
    page: window.location.pathname[window.location.pathname.length - 1] ?? 1,
  });

  const getUsers = () => {
    setState({
      ...state,
      isLoading: true,
      isRequesting: true,
    });
    api
      .get(
        `/admin/users/waitlist`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        let data = res.data;
        setState({
          ...state,
          isLoading: false,
          isRequesting: false,
          total: data.total,
          users: data.data,
        });
      })
      .catch((err) => {
        // console.log(AuthService.getAxiosHeaders().headers);
        // console.log(err.response);
        setState({
          ...state,
          isLoading: false,
          isRequesting: false,
        });
      
      });
  };

  const fetchByPagination = (count) => {
    setState({ ...state, isRequesting: true });
    // Getting users ..............
    api
      .get(
        `/admin/users/waitlist?limit=10&skip=${
          count === 1 ? 1 : count * 5 - 5
        }`,
        AuthService.getAxiosHeaders(),
      )
      .then((res) => {
        let data = res.data;
        setState({
          ...state,
          isLoading: false,
          isRequesting: false,
          total: data.total,
          users: data.data,
          page: count,
        });
        history.push(`/admin/wait-list/${count}`);
      })
      .catch((e) => {
        setState({ ...state, isRequesting: false });
        // console.log(e.response ? e : e);
      });
  };

  useEffect(() => {
    try {
      if (+window.location.pathname[window.location.pathname.length - 1]) {
        fetchByPagination(
          +window.location.pathname[window.location.pathname.length - 1],
        );
        setState({
          ...state,
          page: window.location.pathname[window.location.pathname.length - 1],
        });
      } else {
        getUsers();
      }
    } catch (e) {
      return;
    }

    // eslint-disable-next-line
  }, []);
  return (
    <Fragment>
      <MetaTags>
        <title>Wait-List - HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {state.sidebar && (
              <SideNavMobile
                close={() => setState({ ...state, sidebar: false })}
              />
            )}
            <SideNav className={"bigScreen"} active="users" />

            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="index.html">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    id="kt_aside_toggle"
                    onClick={() =>
                      setState({ ...state, sidebar: !state.sidebar })
                    }
                  >
                    <span className="svg-icon svg-icon-2x me-n1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>

              <Header title={`Wait List`} />
              {state.isLoading ? (
                <div className="pre-loader">
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                  >
                    <div className="container-xxl" id="kt_content_container">
                      {state.isRequesting ? (
                        <TableLoader></TableLoader>
                      ) : (
                        <>
                          <div className="d-flex flex-wrap flex-stack pb-7">
                            <div className="d-flex flex-wrap align-items-center my-1">
                              <h3 className="fw-bolder me-5 my-1 text-white">
                                {state.total} Users Found
                              </h3>
                            </div>
                          </div>
                          <div className="card card-flush">
                            <div className="card-body pt-0">
                              {state.users?.length === 0 ? (
                                <div className="text-center py-20">
                                  <span className="symbol">
                                    <span className="svg-icon svg-icon-3x svg-icon-primary">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <g
                                          stroke="none"
                                          strokeWidth="1"
                                          fill="none"
                                          fill-rule="evenodd"
                                        >
                                          <rect
                                            x="0"
                                            y="0"
                                            width="24"
                                            height="24"
                                          />
                                          <rect
                                            fill="#000000"
                                            opacity="0.3"
                                            x="2"
                                            y="2"
                                            width="10"
                                            height="12"
                                            rx="2"
                                          />
                                          <path
                                            d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                            fill="#000000"
                                          />
                                        </g>
                                      </svg>
                                    </span>
                                  </span>
                                  <p className="text-gray-400 fs-4 fw-bold py-3">
                                    No Users Found.
                                  </p>
                                </div>
                              ) : (
                                <div className="table-responsive">
                                  <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                                    <thead className="fs-7 text-gray-400 text-uppercase">
                                      <tr>
                                        <th className="min-w-120px">Profile</th>
                                        <th className="min-w-20px">
                                          User Profile
                                        </th>
                                        <th className="min-w-90px">
                                          Phone Number
                                        </th>
                                        <th className="min-w-90px">Date</th>
                                        <th className="min-w-90px"></th>
                                      </tr>
                                    </thead>

                                    <tbody className="user-table-body">
                                      {state.users.map((user, idx) => {
                                        let date = format(`${user?.updatedAt}`);
                                        return (
                                          <tr key={idx}>
                                            <td>
                                              <div
                                                className={`flex j-c-c bg-light-${
                                                  [
                                                    "warning",
                                                    "primary",
                                                    "success",
                                                  ][
                                                    Math.floor(
                                                      Math.random() *
                                                        (2 - 0 + 1),
                                                    ) + 0
                                                  ]
                                                }`}
                                                style={{
                                                  width: "40px",
                                                  height: "40px",
                                                  borderRadius: "10px",
                                                }}
                                              >
                                                <i className="fas fa-user"></i>
                                              </div>
                                            </td>
                                            <td>
                                              <div
                                                className="v-flex"
                                                style={{ minWidth: "200px" }}
                                              >
                                                <small>
                                                  <b>Name : </b>
                                                  {user?.name}
                                                </small>
                                                <small>
                                                  <b>Email : </b>
                                                  {user?.email}
                                                </small>
                                              </div>
                                            </td>
                                            <td>
                                              <div
                                                className="flex"
                                                style={{ minWidth: "130px" }}
                                              >
                                                <small>{user.phone}</small>
                                              </div>
                                            </td>
                                            <td>
                                              <div
                                                className="flex"
                                                style={{ minWidth: "140px" }}
                                              >
                                                <small className="mr-1">{`${date[0]}`}</small>
                                                <small>{`${date[1]}`}</small>
                                              </div>
                                            </td>
                                            <td>
                                              <div className="flex">
                                                <a
                                                  href={`mailto:${user.email}`}
                                                  className="bg-primary text-white flex text-white"
                                                  style={{
                                                    borderRadius: "5px",
                                                    minWidth: "110px",
                                                    padding: "0.5rem 1rem",
                                                    margin: "0.5rem 0",
                                                  }}
                                                >
                                                  <i className="fa fa-envelope mr-1 text-white"></i>
                                                  <small className="">
                                                    Send Mail
                                                  </small>
                                                </a>
                                                <a
                                                  href={`tel:${user.phone}`}
                                                  className="flex j-c-c bg-success mr-1 ml-1"
                                                  role="button"
                                                  style={{
                                                    minWidth: "30px",
                                                    minHeight: "30px",
                                                    borderRadius: "8px",
                                                  }}
                                                >
                                                  <i className="fa fa-phone text-white"></i>
                                                </a>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              )}
                            </div>
                          </div>
                        </>
                      )}

                      <div
                        className="pt-10"
                        style={{
                          opacity: `${state.isRequesting ? "0.5" : "1"}`,
                          pointerEvents: `${state.isRequesting ? "none" : ""}`,
                        }}
                      >
                        {state.total > 10 && (
                          <Paginate
                            page={state.page}
                            total={state.total}
                            setPage={(count) => {
                              fetchByPagination(count);
                              setState({ ...state, page: count });
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <Footer />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default WaitList;
