import React, { Fragment, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import SideNavMobile from "./include/sideNavMobile";
import { useQuery } from "react-query";
import "./styles/settings.css";
import api from "../../misc/api";
import dayjs from "dayjs";

const Swap = () => {
  AuthService.checkAdminProtected();

  const [state, setState] = useState({
    sidebar: false,
  });
  const [status, setStatus] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [page, setPage] = useState(0);
  const limit = 10;

  useEffect(() => {
    setPage(0);
    setPageNumber(0);
  }, [year, month, status, selectedDate]);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "year":
        setYear(value);
        break;
      case "month":
        setMonth(value);
        break;
      case "status":
        setStatus(value);
        break;
      case "date":
        setSelectedDate(value);
        break;
      default:
        break;
    }
  };

  const fetchSwapOrders = async () => {
    let url = `admin/orders/swap`;

    const params = [];
    if (limit) params.push(`limit=${limit}`);
    if (year) params.push(`year=${year}`);
    if (month) params.push(`month=${month}`);
    if (status) params.push(`status=${status}`);
    if (page) params.push(`skip=${page + limit}`);
    if (selectedDate) params.push(`date=${selectedDate}`);

    if (params.length > 0) {
      url += `?${params.join("&")}`;
    }

    const response = await api.get(url, AuthService.getAxiosAdminHeaders());
    return response.data;
  };

  const { data } = useQuery(
    ["swapOrders", limit, year, month, status, page, selectedDate],
    fetchSwapOrders
  );

  const handlePrevPage = () => {
    setPage(page - limit);
    setPageNumber(pageNumber - 1);
  };

  const handleNextPage = () => {
    if (data?.hasMore) {
      setPage(page + limit);
      setPageNumber(pageNumber + 1);
    }
  };

  const totalPages = Math.abs(Math.ceil(data?.total / limit - 1));

  function getStatusColorClass(status) {
    switch (status) {
      case "waiting":
        return "bg-green-100 text-green-500 px-2 py-1 rounded-full text-xs";
      case "confirming":
      case "exchanging":
        return "bg-cyan-100 text-cyan-500 px-2 py-1 rounded-full text-xs";
      case "sending":
        return "bg-purple-100 text-purple-500 px-2 py-1 rounded-full text-xs";
      case "finished":
        return "bg-green-100 text-green-500 px-2 py-1 rounded-full text-xs";
      case "failed":
        return "bg-red-100 text-red-500 px-2 py-1 rounded-full text-xs";
      case "refunded":
        return "bg-gray-100 text-gray-500 px-2 py-1 rounded-full text-xs";
      default:
        return "";
    }
  }

  const clearFilters = () => {
    setYear("");
    setMonth("");
    setStatus("");
    setSelectedDate("");
    setPage(0);
    setPageNumber(0);
  };


  return (
    <Fragment>
      <MetaTags>
        <title>Swap Transactions - HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {state.sidebar && (
              <SideNavMobile
                close={() => setState({ ...state, sidebar: false })}
              />
            )}{" "}
            <SideNav className={"bigScreen"} active="swap" />
            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="index.html">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    id="kt_aside_toggle"
                    onClick={() =>
                      setState({ ...state, sidebar: !state.sidebar })
                    }
                  >
                    <span className="svg-icon svg-icon-2x me-n1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
              <Header title={`Swap Transactions`} />

              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="container-xxl px-8" id="kt_content_container">
                  <div className="card mb-7">
                    <div className="card-body"></div>
                  </div>
                  <div className="flex gap-4 flex-col lg:flex-row lg:flex-wrap items-start">
                    <div className="flex gap-4 mb-2">
                      <label>Years</label>
                      <select
                        name="year"
                        value={year}
                        onChange={handleFilterChange}
                        className="form-select"
                      >
                        <option value="">All Years</option>
                        {[...Array(5)].map((_, i) => (
                          <option key={i} value={new Date().getFullYear() - i}>
                            {new Date().getFullYear() - i}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex gap-4 mb-2">
                      <label>Months</label>
                      <select
                        name="month"
                        value={month}
                        onChange={handleFilterChange}
                        className="form-select"
                      >
                        <option value="">All Months</option>
                        {[...Array(12)].map((_, i) => (
                          <option key={i} value={i}>
                            {new Date(0, i).toLocaleString("default", {
                              month: "long",
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex gap-4 mb-2">
                      <label>Status</label>
                      <select
                        name="status"
                        value={status}
                        onChange={handleFilterChange}
                        className="form-select"
                      >
                        <option value="">All Statuses</option>
                        <option value="finished">Finished</option>
                        <option value="failed">Failed</option>
                        <option value="waiting">Waiting</option>
                        <option value="refunded">Refunded</option>
                        <option value="sending">Sending</option>
                        <option value="exchanging">Exchanging</option>
                      </select>
                    </div>
                    <div className="flex gap-4 mb-2">
                      <label className="whitespace-nowrap">
                        {selectedDate ? "Date" : "Select date"}
                      </label>
                      <input
                        aria-label="Date"
                        type="date"
                        name="date"
                        value={selectedDate}
                        onChange={handleFilterChange}
                      />
                    </div>
                  </div>
                  <div className="flex gap-4 lg:justify-end pb-7">
                    <div
                      className="text-red-600 cursor-pointer"
                      onClick={clearFilters}
                    >
                      Clear Filters
                    </div>
                  </div>
                  <div className="card card-flush">
                    {data?.data?.length === 0 ? (
                      <div className="text-center py-20">
                        <span className="symbol">
                          <span className="svg-icon svg-icon-3x svg-icon-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <rect
                                  fill="#000000"
                                  opacity="0.3"
                                  x="2"
                                  y="2"
                                  width="10"
                                  height="12"
                                  rx="2"
                                />
                                <path
                                  d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                  fill="#000000"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                        <p className="text-gray-400 fs-4 fw-bold py-3">
                          No transactions found.
                        </p>
                      </div>
                    ) : (
                      <div className="card-body pt-0">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                            <thead className="fs-7 text-gray-400 text-uppercase">
                              <tr>
                                <th className="min-w-120px">Currency</th>
                                <th className="min-w-120px">Date</th>
                                <th className="min-w-90px text-end">Amount</th>
                                <th className="min-w-90px text-end">Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.data?.map((transaction, index) => (
                                <tr
                                  key={transaction.id}
                                  className="border-bottom"
                                >
                                  <td className="d-flex align-items-center">
                                    <div className="flex flex-col my-2 mr-4">
                                      <div className="relative w-[30px] md:w-full">
                                        <img
                                          src={transaction.fromCurrencyIconUrl}
                                          alt=""
                                          className="w-[20px] h-[20px]  md:w-[30px] md:h-[30px] rounded-[2px] relative "
                                        />
                                        <img
                                          src={transaction.toCurrencyIconUrl}
                                          alt=""
                                          className="w-[20px] h-[20px]  md:w-[30px] md:h-[30px] rounded-md  absolute top-0 left-0 ml-6 mt-4"
                                        />
                                      </div>
                                    </div>

                                    <div className="ml-4">
                                      <span className="fw-bold">
                                        {transaction.fromCurrency.toUpperCase()}{" "}
                                        {transaction.fromCurrency ===
                                          "usdt" && (
                                          <small className="bg-green-500 px-2 py-1 rounded text-white">
                                            {transaction.fromNetwork.toLowerCase()}
                                          </small>
                                        )}
                                        &nbsp;&rarr;&nbsp;
                                        {transaction.toCurrency.toUpperCase()}{" "}
                                        {transaction.toCurrency === "usdt" && (
                                          <small className="bg-green-500 px-2 py-1 rounded text-white">
                                            {transaction.toNetwork.toLowerCase()}
                                          </small>
                                        )}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <small className="text-gray-400">
                                      {dayjs(
                                        new Date(transaction.createdAt)
                                      ).format("MMM D, YYYY hh:mm a")}
                                    </small>
                                  </td>
                                  <td className="text-end">
                                    {transaction.amount}{" "}
                                    {transaction.fromCurrency.toUpperCase()}
                                  </td>
                                  <td className="text-end">
                                    <span
                                      className={getStatusColorClass(
                                        transaction.status
                                      )}
                                    >
                                      {transaction.status}
                                    </span>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="container-xxl px-8">
                  <div className="pt-10 flex w-full justify-between">
                    <button
                      className={`h-3 w-4 ${
                        pageNumber === 0
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      disabled={pageNumber === 0}
                      onClick={handlePrevPage}
                    >
                      <i className="fa fa-arrow-left mr-1"></i>
                      <p>Previous</p>
                    </button>

                    <div>
                      <span className="text-[12px]">
                        Page {pageNumber + 1} of {totalPages}
                      </span>
                    </div>

                    <button
                      className={`h-3 w-4 ${
                        pageNumber + 1 === totalPages
                          ? "opacity-50 cursor-not-allowed"
                          : "cursor-pointer"
                      }`}
                      onClick={handleNextPage}
                      disabled={!data?.hasMore || pageNumber + 1 === totalPages}
                    >
                      <i className="fa fa-arrow-right mr-1"></i>
                      <p>Next</p>
                    </button>
                  </div>
                </div>
              </div>

              <Footer className="px-8" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Swap;
