import React, { useState } from "react";
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from "@headlessui/react";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import AuthService from "../../misc/AuthService";
import api from "../../misc/api";





function UserInfoTabs(props) {
  const limit = 10;
  const [orderPageNumber, setOrderPageNumber] = useState(0);
  const [orderPage, setOrderPage] = useState(0);

  const [referralPageNumber, setReferralPageNumber] = useState(0);
  const [referralPage, setReferralPage] = useState(0);

  const [swapPageNumber, setSwapPageNumber] = useState(0)
  const [swapPage, setSwapPage] = useState(0)



  function getStatusColorClass(status) {
    switch (status) {
      case "waiting":
        return "bg-green-100 text-green-500 px-2 py-1 rounded-full text-xs";
      case "confirming":
      case "exchanging":
        return "bg-cyan-100 text-cyan-500 px-2 py-1 rounded-full text-xs";
      case "sending":
        return "bg-purple-100 text-purple-500 px-2 py-1 rounded-full text-xs";
      case "finished":
        return "bg-green-100 text-green-500 px-2 py-1 rounded-full text-xs";
      case "failed":
        return "bg-red-100 text-red-500 px-2 py-1 rounded-full text-xs";
      case "refunded":
        return "bg-gray-100 text-gray-500 px-2 py-1 rounded-full text-xs";
      default:
        return "";
    }
  }

  const handleOrderPrevPage = () => {
    setOrderPage(orderPage - limit)
    setOrderPageNumber(orderPageNumber - 1);
  };

  const handleOrderNextPage = () => {
    if (data?.hasMore) {
      setOrderPage(orderPage + limit)
      setOrderPageNumber(orderPageNumber + 1);

    }
  };

  const handleReferralPrevPage = () => {
    setReferralPage(referralPage - limit)
    setReferralPageNumber(referralPageNumber - 1);
  };

  const handleReferralNextPage = () => {
    if (referrals?.hasMore) {
      setReferralPage(referralPage + limit)
      setReferralPageNumber(referralPageNumber + 1);

    }
  };

  const handleSwapPrevPage = () => {
    setSwapPage(swapPage - limit)
    setSwapPageNumber(swapPageNumber - 1);
  };

  const handleSwapNextPage = () => {
    if (swap?.hasMore) {
      setSwapPage(swapPage + limit)
      setSwapPageNumber(swapPageNumber + 1);

    }
  };

  const fetchOrders = async () => {
    let url = `/admin/orders?limit=${limit}&userId=${props.userId}&skip=${orderPage + limit}`
    const response = await api.get(url, AuthService.getAxiosAdminHeaders());
    return response.data;
  };


  const { data } = useQuery(
    ["orders", limit, orderPage],
    fetchOrders
  );

  const fetchReferrals = async () => {
    let url = `/admin/users/referred?limit=${limit}&userId=${props.userId}`
    const response = await api.get(url, AuthService.getAxiosAdminHeaders());
    return response.data;
  };

  const { data: referrals } = useQuery(
    ["referrals", limit, referralPage],
    fetchReferrals
  );

  const fetchSwapOrders = async () => {
    let url = `/admin/orders/swap?limit=${limit}&userId=${props.userId}`

    const response = await api.get(url, AuthService.getAxiosAdminHeaders());
    return response.data;
  };

  const { data: swap } = useQuery(
    ["swapOrders", limit, swapPage],
    fetchSwapOrders
  );

 


  return (
    <div className="w-full">
      <div className="flex flex-col items-start w-full">
        <TabGroup className="w-full">
          <TabList className="flex gap-4 mb-6">
            <Tab className="rounded-full py-1 px-3 text-sm/6 font-semibold text-black focus:outline-none data-[selected]:bg-black/10 data-[hover]:bg-black/5 data-[selected]:data-[hover]:bg-black/10 data-[focus]:outline-1 data-[focus]:outline-black">
              Orders
            </Tab>
            <Tab className="rounded-full py-1 px-3 text-sm/6 font-semibold text-black focus:outline-none data-[selected]:bg-black/10 data-[hover]:bg-black/5 data-[selected]:data-[hover]:bg-black/10 data-[focus]:outline-1 data-[focus]:outline-black">
              Referrals
            </Tab>
            <Tab className="rounded-full py-1 px-3 text-sm/6 font-semibold text-black focus:outline-none data-[selected]:bg-black/10 data-[hover]:bg-black/5 data-[selected]:data-[hover]:bg-black/10 data-[focus]:outline-1 data-[focus]:outline-black">
              Swap Orders
            </Tab>
          </TabList>

          <TabPanels>
            {/* Orders Tab */}
            <TabPanel>
              <div className="flex flex-col w-full  items-start  ">
                <h3 className="text-lg font-semibold mb-4">
                  User Orders
                </h3>
                <div className="overflow-x-auto  border rounded-lg w-full h-[600px]">
                  {data?.data?.length ? (
                    <table className="w-full ">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="py-5 px-4 text-left">Order Type</th>
                          <th className="py-5 px-4 text-left">Date</th>
                          <th className="py-5 px-4 text-left">Amount</th>
                          <th className="py-5 px-4 text-left">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.data.map((order, index) => (
                          <tr key={index}>
                            <td className="py-4 px-4">{order.type} order</td>
                            <td className="py-4 px-4">
                              {dayjs(order.date).format("MMM D, YYYY h:mm A")}
                            </td>
                            <td className="py-4 px-4">{order.amount}</td>
                            <td className="py-4 px-4">{order.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <table className="w-full ">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="py-5 px-4 text-left">Order Type</th>
                          <th className="py-5 px-4 text-left">Date</th>
                          <th className="py-5 px-4 text-left">Amount</th>
                          <th className="py-5 px-4 text-left">Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="4" className="py-64 px-4 text-center">
                            <div className="mt-2">
                              <i class="fas fa-wallet fa-3x"></i>
                            </div>
                            <div className="mt-2">    No orders available</div>


                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}


                </div>
                <div className="flex justify-between p-4 w-full">
                  <button
                    onClick={handleOrderPrevPage}
                    disabled={orderPageNumber === 0}
                    className="flex items-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <i className="fa fa-arrow-left mr-2"></i>Previous
                  </button>
                  <span>Page {orderPageNumber + 1}</span>
                  <button
                    onClick={handleOrderNextPage}
                    className="flex items-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Next<i className="fa fa-arrow-right ml-2"></i>
                  </button>
                </div>
              </div>
            </TabPanel>

            {/* Referrals Tab */}
            <TabPanel>
              <div className="flex flex-col w-full items-start ">
                <h3 className="text-lg font-semibold mb-4">User Referrals</h3>
                <div className="overflow-x-auto w-full border rounded-lg h-[650px]">
                  {referrals?.data?.length ? (
                    <table className="w-full">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="py-5 px-4 text-left">Full Name</th>
                          <th className="py-5 px-4 text-left">
                            Registration Date
                          </th>
                          <th className="py-5 px-4 text-left">Points Earned</th>
                        </tr>
                      </thead>
                      <tbody>
                        {referrals.data.map((referral, index) => (
                          <tr key={index}>
                            <td className="py-4 px-4">
                              {referral.firstName} {referral.lastName}
                            </td>
                            <td className="py-4 px-4">
                              {dayjs(referral.createdAt).format(
                                "MMM D, YYYY h:mm A"
                              )}
                            </td>
                            <td className="py-4 px-4">{referral.pointsEarned}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>) : (
                    <table className="w-full ">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="py-5 px-4 text-left">Full Name</th>
                          <th className="py-5 px-4 text-left">
                            Registration Date
                          </th>
                          <th className="py-5 px-4 text-left">Points Earned</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="4" className="py-64 px-4 text-center">
                            <div >
                              <i class="fa fa-user fa-3x" aria-hidden="true"></i>

                            </div>
                            <div className="mt-4"> No referrals available </div>



                          </td>
                        </tr>
                      </tbody>
                    </table>)}

                </div>
                <div className="flex justify-between p-4 w-full">
                  <button
                    onClick={handleReferralPrevPage}
                    disabled={referralPageNumber === 0}
                    className="flex items-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <i className="fa fa-arrow-left mr-2"></i>Previous
                  </button>
                  <span>Page {referralPageNumber + 1}</span>
                  <button
                    onClick={handleReferralNextPage}
                    className="flex items-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Next<i className="fa fa-arrow-right ml-2"></i>
                  </button>
                </div>
              </div>
            </TabPanel>
            {/* swap orders */}
            <TabPanel>
              <div className="flex flex-col w-full items-start ">
                <h3 className="text-lg font-semibold mb-4">User Swap Orders</h3>
                <div className="overflow-x-auto w-full border rounded-lg h-[650px]">
                  {swap?.data?.length ? (
                    <table className="w-full ">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="py-5 px-4 text-left">Currency</th>
                          <th className="py-5 px-4 text-left">
                            Date
                          </th>
                          <th className="py-5 px-4 text-left">Amount</th>
                          <th className="py-5 px-4 text-left">Status</th>

                        </tr>
                      </thead>
                      <tbody>
                        {swap.data.map((transaction, index) => (
                          <tr key={index}>
                            <td className="d-flex align-items-center">
                              <div className="flex flex-col my-4 mr-4 p-2">
                                <div className="relative w-[30px] md:w-full">
                                  <img
                                    src={transaction.fromCurrencyIconUrl}
                                    alt=""
                                    className="w-[20px] h-[20px]  md:w-[25px] md:h-[25px] rounded-[2px] relative "
                                  />
                                  <img
                                    src={transaction.toCurrencyIconUrl}
                                    alt=""
                                    className="w-[20px] h-[20px]  md:w-[25px] md:h-[25px] rounded-md  absolute top-0 left-0 ml-6 mt-4"
                                  />
                                </div>
                              </div>

                              <div className="ml-4">
                                <span className="fw-bold">
                                  {transaction.fromCurrency.toUpperCase()}{" "}
                                  {transaction.fromCurrency ===
                                    "usdt" && (
                                      <small className="bg-green-500 px-2 py-1 rounded text-white">
                                        {transaction.fromNetwork.toLowerCase()}
                                      </small>
                                    )}
                                  &nbsp;&rarr;&nbsp;
                                  {transaction.toCurrency.toUpperCase()}{" "}
                                  {transaction.toCurrency === "usdt" && (
                                    <small className="bg-green-500 px-2 py-1 rounded text-white">
                                      {transaction.toNetwork.toLowerCase()}
                                    </small>
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <small className="text-gray-400">
                                {dayjs(
                                  new Date(transaction.createdAt)
                                ).format("MMM D, YYYY hh:mm a")}
                              </small>
                            </td>
                            <td className="text-end">
                              {transaction.amount}{" "}
                              {transaction.fromCurrency.toUpperCase()}
                            </td>
                            <td className="text-end">
                              <span
                                className={getStatusColorClass(
                                  transaction.status
                                )}
                              >
                                {transaction.status}
                              </span>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>) : (
                    <table className="w-full ">
                      <thead>
                        <tr className="bg-gray-100">
                          <th className="py-5 px-4 text-left">Currency</th>
                          <th className="py-5 px-4 text-left">
                            Date
                          </th>
                          <th className="py-5 px-4 text-left">Amount</th>
                          <th className="py-5 px-4 text-left">Status</th>

                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td colSpan="4" className="py-64 px-4 text-center">
                            <div className="mt-2">
                              <i class="fas fa-wallet fa-3x"></i>
                            </div>
                            <div className="mt-2">
                              No swap orders available
                            </div>                          </td>
                        </tr>
                      </tbody>
                    </table>)}

                </div>
                <div className="flex justify-between p-4 w-full">
                  <button
                    onClick={handleSwapPrevPage}
                    disabled={swapPageNumber === 0}
                    className="flex items-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <i className="fa fa-arrow-left mr-2"></i>Previous
                  </button>
                  <span>Page {swapPageNumber + 1}</span>
                  <button
                    onClick={handleSwapNextPage}
                    className="flex items-center cursor-pointer disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    Next<i className="fa fa-arrow-right ml-2"></i>
                  </button>
                </div>
              </div>
            </TabPanel>
          </TabPanels>
        </TabGroup>
      </div>
    </div>
  );
}

export default UserInfoTabs;
