import React from "react";
import { useQuery } from "react-query";
import AuthService from "../../misc/AuthService";
import api from "../../misc/api";

function Threshhold() {
  const getAssets = async () => {
    try {
      const response = await api.get(
        `/assets/balances`,
        AuthService.getAxiosAdminHeaders()
      );
      return response.data;
    } catch (error) {
      console.error("Failed to fetch assets:", error);
      throw error;
    }
  };

  const { data, isLoading } = useQuery("assets", getAssets);

  if (isLoading) {
    return <div  className="p-12">Loading.. </div>;
  }



  const { total, assetBalances } = data;

  return (
    <div className="p-6">
      <div>
        {/* Header Row */}
        <div className="flex items-center justify-between border-b border-gray-300 pb-2 font-bold">
          <div className="w-1/4">Coin</div>
          <div className="w-1/4 text-left">Mass Sender</div>
          <div className="w-1/4 text-left">Mass Collection</div>
          <div className="w-1/4 text-left">Total</div>
        </div>

        {/* Dynamic Rows */}
        {assetBalances.map((asset, index) => (
          <React.Fragment key={asset.code}>
            <div className="flex items-center justify-between border-b border-gray-300 py-5">
              <div className="w-1/4 flex items-center gap-2">
                <img src={asset.icon} alt={asset.name} className="w-8 h-8" />
              </div>
              <div className="w-1/4 text-left">
                ${asset.massSenderBalance.toFixed(2)}
              </div>
              <div className="w-1/4 text-left">
                ${asset.massCollectionBalance.toFixed(2)}
              </div>
              <div className="w-1/4 text-left text-green-500 font-semibold">
                ${(
                  asset.massSenderBalance + asset.massCollectionBalance
                ).toFixed(2)}
              </div>
            </div>
          </React.Fragment>
        ))}

        {/* Overall Total Row */}
        <div className="flex items-center justify-between font-bold pt-4">
          <div className="w-1/4">Overall Total</div>
          <div className="w-1/4"></div>
          <div className="w-1/4"></div>
          <div className="w-1/4 text-green-500">${total.toFixed(2)}</div>
        </div>
      </div>
    </div>
  );
}

export default Threshhold;