import React, { Fragment, useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import { TableLoader } from "./include/Loaders";
import Footer from "./include/Footer";
import AuthService from "../../misc/AuthService";
import refreshToken from "../../misc/RefreshToken";
import Paginate from "../client/include/Paginate";
import SideNavMobile from "./include/sideNavMobile";
import {
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import UserInfo from "./UserInfo";
import api from "../../misc/api";

function AdminUsers() {
  AuthService.checkAdminProtected();
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const [sidebar, setSidebar] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const page = params.page ? params.page : 1;
  const limit = 10;

  const { data, isLoading, isError, error, refetch } = useQuery(
    ["adminUsers", page],
    () =>
      api
        .get(
          `/admin/users?limit=${limit}&skip=${page === 1 ? 0 : page * 10 - 10
          }`,
          AuthService.getAxiosAdminHeaders()
        )
        .then((res) => res.data),
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      retry: 1,
      onError: (error) => {
        if (error.response && error.response.status === 401) {
          refreshToken("admin");
        }
      },
    }
  );


  useEffect(() => {
    refetch();
  }, [location.pathname, refetch]);

  const handleSearch = () => {
    refetch();
  };

  const handleToggleSidebar = () => {
    setSidebar(!sidebar);
  };

  const handlePageChange = (count) => {
    history.push(`/admin/users/${count}`);
  };

  const handleUserDetails = (
    id,
    firstName,
    lastName,
    dateOfBirth,
    email,
    accountLevel,
    createdAt
  ) => {
    setSelectedUser({
      id,
      firstName,
      lastName,
      dateOfBirth,
      email,
      accountLevel,
      createdAt,
    });
  };

  return (
    <Fragment>
      <MetaTags>
        <title>Users - HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {sidebar && <SideNavMobile close={() => setSidebar(false)} />}
            <SideNav className={"bigScreen"} active="users" />

            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="index.html">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    id="kt_aside_toggle"
                    onClick={handleToggleSidebar}
                  >
                    <span className="svg-icon svg-icon-2x me-n1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>

              <Header title={`Users`} />

              {selectedUser ? (
                <UserInfo user={selectedUser} />
              ) : isLoading ? (
                <TableLoader />
              ) : (
                <>
                  <div
                    className="content d-flex flex-column flex-column-fluid"
                    id="kt_content"
                  >
                    <div className="container-xxl" id="kt_content_container">
                      <div className="card mb-7">
                        <div className="card-body">
                          <div className="d-flex align-items-center">
                            <div className="position-relative w-md-400px me-md-2">
                              <span className="svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 translate-middle ms-6">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                >
                                  <rect
                                    opacity="0.5"
                                    x="17.0365"
                                    y="15.1223"
                                    width="8.15546"
                                    height="2"
                                    rx="1"
                                    transform="rotate(45 17.0365 15.1223)"
                                    fill="black"
                                  />
                                  <path
                                    d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                    fill="black"
                                  />
                                </svg>
                              </span>
                              <input
                                type="text"
                                className="form-control form-control-solid ps-10"
                                name="search"
                                value={search}
                                placeholder="Search"
                                onChange={(e) => setSearch(e.target.value)}
                              />
                            </div>
                            <div className="d-flex align-items-center">
                              <button
                                onClick={handleSearch}
                                className="btn btn-primary me-5"
                              >
                                Search
                              </button>
                              <a
                                id="kt_horizontal_search_advanced_link"
                                className="btn btn-link"
                                data-bs-toggle="collapse"
                                href="#kt_advanced_search_form"
                              >
                                Advanced Search
                              </a>
                            </div>
                            <div className="flex gap-4 ml-6">
                              {/* <label>Users</label>
                            <select name="users" className="form-select">
                              <option value="">All Users</option>
                              <option value="users">Active Users</option>
                            </select> */}
                            </div>
                          </div>

                        </div>

                      </div>

                      {isError ? (
                        <div className="text-center py-20">
                          <span className="symbol">
                            <span className="svg-icon svg-icon-3x svg-icon-primary">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <g
                                  stroke="none"
                                  strokeWidth="1"
                                  fill="none"
                                  fillRule="evenodd"
                                >
                                  <rect x="0" y="0" width="24" height="24" />
                                  <rect
                                    fill="#000000"
                                    opacity="0.3"
                                    x="2"
                                    y="2"
                                    width="10"
                                    height="12"
                                    rx="2"
                                  />
                                  <path
                                    d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                    fill="#000000"
                                  />
                                </g>
                              </svg>
                            </span>
                          </span>
                          <p className="text-gray-400 fs-4 fw-bold py-3">
                            {error.message || "Error fetching data."}
                          </p>
                        </div>
                      ) : (
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                            <thead className="fs-7 text-gray-400 text-uppercase">
                              <tr>
                                <th className="min-w-120px">Profile</th>

                             
                                <th className="min-w-90px">Date</th>
                                <th className="min-w-50px text-end"></th>
                              </tr>
                            </thead>

                            <tbody className="user-table-body">
                              {data?.data.map((user, idx) => {
                                let date = dayjs(
                                  new Date(user.createdAt)
                                ).format("MMM D, YYYY hh:mm a");
                                return (
                                  <tr key={idx}>

                                    <td>
                                      <div className="flex">
                                        <div className="v-flex">
                                         
                                          <div className="v-flex">
                                        
                                            <small
                                              className="bg-primary p-3 text-white"
                                              style={{ borderRadius: "5px" }}
                                            >
                                              {user.roles?.map((role, id, arr) => (
                                                <b key={id}>
                                                  {role}
                                                  {`${arr?.length > 1 ? "," : ""}`}
                                                </b>
                                              ))}
                                            </small>
                                            <small>
                                              <b>Name : </b>
                                              {user.user.firstName}{" "}
                                              {user.user.lastName}
                                            </small>
                                            <small>
                                              <b>Email : </b>
                                              {user.emailIdentity.email}
                                            </small>
                                          </div>
                                        </div>
                                      </div>
                                    </td>
                                  
                                 
                                    <td>
                                      <div
                                        className="flex"
                                        style={{ minWidth: "140px" }}
                                      >
                                        <small>{date}</small>
                                      </div>
                                    </td>
                                    <td>
                                      <button
                                        className="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
                                        onClick={() =>
                                          handleUserDetails(
                                            user.userId,
                                            user.user.firstName,
                                            user.user.lastName,
                                            user.user.dateOfBirth,
                                            user.emailIdentity.email,
                                            user.accountLevel,
                                            user.createdAt
                                          )
                                        }
                                      >
                                        <span className="svg-icon svg-icon-2">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                          >
                                            <rect
                                              opacity="0.5"
                                              x="18"
                                              y="13"
                                              width="13"
                                              height="2"
                                              rx="1"
                                              transform="rotate(-180 18 13)"
                                              fill="black"
                                            />
                                            <path
                                              d="M15.4343 12.5657L11.25 16.75C10.8358 17.1642 10.8358 17.8358 11.25 18.25C11.6642 18.6642 12.3358 18.6642 12.75 18.25L18.2929 12.7071C18.6834 12.3166 18.6834 11.6834 18.2929 11.2929L12.75 5.75C12.3358 5.33579 11.6642 5.33579 11.25 5.75C10.8358 6.16421 10.8358 6.83579 11.25 7.25L15.4343 11.4343C15.7467 11.7467 15.7467 12.2533 15.4343 12.5657Z"
                                              fill="black"
                                            />
                                          </svg>
                                        </span>
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      )}

                      <div
                        className="pt-10"
                        style={{
                          opacity: `${isLoading ? "0.5" : "1"}`,
                          pointerEvents: `${isLoading ? "none" : ""}`,
                        }}
                      >
                        {data && data.total > 10 && (
                          <Paginate
                            page={page}
                            total={data.total}
                            setPage={handlePageChange}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <Footer />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AdminUsers;
