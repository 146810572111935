import axios from 'axios';
import Constants from './Constants';

const api = axios.create({
  baseURL: Constants.API_BASE_URL, 
  timeout: 10000,  
});

api.interceptors.response.use(
  (response) => response, 
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('admin_access_token');
      sessionStorage.removeItem('admin_access_token');

      

      window.location.href = '/admin/login';  
    }

    return Promise.reject(error);
  }
);

export default api;
