import React, { Fragment, useState, } from "react";
import MetaTags from "react-meta-tags";
import AuthService from "../../misc/AuthService";
import SideNav from "./include/SideNav";
import Header from "./include/Header";
import Footer from "./include/Footer";
import SideNavMobile from "./include/sideNavMobile";
import "./styles/settings.css";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import api from "../../misc/api";



const Leadeboard = () => {
  AuthService.checkAdminProtected();

  const [state, setState] = useState({
    sidebar: false,
  });

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "year":
        setYear(value);
        break;
      case "month":
        setMonth(value);
        break;
      default:
        break;
    }
  };

  const limit = 10;
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const fetchLeaderBoardData = async () => {
    let url = `/admin/users/leaderboard?limit=${limit}&year=${year}&month=${month}`;

    const response = await api.get(url, AuthService.getAxiosAdminHeaders());
    return response.data;
  };

  const { data } = useQuery(
    ["leaderboards", limit, year, month],
    fetchLeaderBoardData
  );



  return (
    <Fragment>
      <MetaTags>
        <title>LeaderBoard- HodlPay</title>
      </MetaTags>
      <div className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-disabled body">
        <div className="d-flex flex-column flex-root">
          <div className="page d-flex flex-row flex-column-fluid">
            {state.sidebar && (
              <SideNavMobile
                close={() => setState({ ...state, sidebar: false })}
              />
            )}{" "}
            <SideNav className={"bigScreen"} active="leaderboard" />
            <div
              className="p-0 wrapper d-flex flex-column flex-row-fluid"
              id="kt_wrapper"
            >
              <div className="header-mobile py-3">
                <div className="container d-flex flex-stack">
                  <div className="d-flex align-items-center flex-grow-1 flex-lg-grow-0">
                    <a href="index.html">
                      <img
                        alt="Logo"
                        src="/assets/media/logos/hodlpay-black.svg"
                        className="h-35px"
                      />
                    </a>
                  </div>

                  <button
                    className="btn btn-icon btn-active-color-primary"
                    id="kt_aside_toggle"
                    onClick={() =>
                      setState({ ...state, sidebar: !state.sidebar })
                    }
                  >
                    <span className="svg-icon svg-icon-2x me-n1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M21 7H3C2.4 7 2 6.6 2 6V4C2 3.4 2.4 3 3 3H21C21.6 3 22 3.4 22 4V6C22 6.6 21.6 7 21 7Z"
                          fill="black"
                        />
                        <path
                          opacity="0.3"
                          d="M21 14H3C2.4 14 2 13.6 2 13V11C2 10.4 2.4 10 3 10H21C21.6 10 22 10.4 22 11V13C22 13.6 21.6 14 21 14ZM22 20V18C22 17.4 21.6 17 21 17H3C2.4 17 2 17.4 2 18V20C2 20.6 2.4 21 3 21H21C21.6 21 22 20.6 22 20Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
              <Header title={`LeaderBoard`} />

              <div
                className="content d-flex flex-column flex-column-fluid"
                id="kt_content"
              >
                <div className="container-xxl px-8 mt-4" id="kt_content_container">
                  <div className="card mb-7">
                    <div className="card-body"></div>
                  </div>
                  <div className="flex gap-4 flex-col lg:flex-row lg:flex-wrap items-start ">
                    <div className="flex gap-4 mb-2">
                      <label>Years</label>
                      <select
                        name="year"
                        value={year}
                        onChange={handleFilterChange}
                        className="form-select"
                      >
                        <option value="">All Years</option>
                        {[...Array(5)].map((_, i) => (
                          <option key={i} value={new Date().getFullYear() - i}>
                            {new Date().getFullYear() - i}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="flex gap-4 mb-2">
                      <label>Months</label>
                      <select
                        name="month"
                        value={month}
                        onChange={handleFilterChange}
                        className="form-select"
                      >
                        <option value="">All Months</option>
                        {[...Array(12)].map((_, i) => (
                          <option key={i} value={i+1}>
                            {new Date(0, i).toLocaleString("default", {
                              month: "long",
                            })}
                          </option>
                        ))}
                      </select>
                    </div>
                 

                  </div>
                  <div className="flex gap-4 lg:justify-end pb-7">
                    <div
                      className="text-red-600 cursor-pointer"
             
                    >
                      Clear Filters
                    </div>
                  </div>
                  <div className="card card-flush">
                    
                  </div>
                  <div className="card card-flush">
                    {!data ?  (
                      <div className="text-center py-20">
                        <span className="symbol">
                          <span className="svg-icon svg-icon-3x svg-icon-primary">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <g
                                stroke="none"
                                strokeWidth="1"
                                fill="none"
                                fill-rule="evenodd"
                              >
                                <rect x="0" y="0" width="24" height="24" />
                                <rect
                                  fill="#000000"
                                  opacity="0.3"
                                  x="2"
                                  y="2"
                                  width="10"
                                  height="12"
                                  rx="2"
                                />
                                <path
                                  d="M4,6 L20,6 C21.1045695,6 22,6.8954305 22,8 L22,20 C22,21.1045695 21.1045695,22 20,22 L4,22 C2.8954305,22 2,21.1045695 2,20 L2,8 C2,6.8954305 2.8954305,6 4,6 Z M18,16 C19.1045695,16 20,15.1045695 20,14 C20,12.8954305 19.1045695,12 18,12 C16.8954305,12 16,12.8954305 16,14 C16,15.1045695 16.8954305,16 18,16 Z"
                                  fill="#000000"
                                />
                              </g>
                            </svg>
                          </span>
                        </span>
                        <p className="text-gray-400 fs-4 fw-bold py-3">
                          No Data found.
                        </p>
                      </div>
                    ) : (
                      <div className="card-body pt-0">
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                            <thead className="fs-7 text-gray-400 text-uppercase">
                              <tr>
                              <th className="min-w-120px">Rank</th>
                                <th className="min-w-120px">Users</th>
                                <th className="min-w-120px">Date Joined</th>
                                <th className="min-w-90px text-end">
                                  Buy Orders(Count)
                                </th>
                                <th className="min-w-90px text-end">
                                  Sell Orders(Count)
                                </th>
                                <th className="min-w-90px text-end">
                                  Total Orders(Count)
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data?.data?.map((users, index) => (
                                <tr key={users.id} className="border-bottom">
                                <td>{index + 1}</td> {/* Add this line to display row numbers */}

                                  <td className="d-flex align-items-center">
                                    <div className="ml-4">
                                      <span className="fw-bold">
                                        {users.firstName} {users.lastName}
                                      </span>
                                    </div>
                                  </td>
                                  <td>
                                    <small className="text-gray-400">
                                      {dayjs(
                                        new Date(users.dateJoined)
                                      ).format("MMM D, YYYY hh:mm a")}
                                    </small>
                                  </td>
                                  <td className="text-end">
                                ${users?.totalBuyOrderAmount}({users?.buyOrderCount})
                                  </td>
                                  <td className="text-end">
                                ${users?.totalSellOrderAmount}({users?.sellOrderCount})
                                  </td>
                                  <td className="text-end">
                                ${users?.totalAmount}({users?.orderCount})
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    )}
                  </div> 
                </div>


                
                
              </div>

              <Footer className="px-8" />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Leadeboard;
